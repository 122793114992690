App {
  text-align: center;
}
    /* body {
      margin: 0;
      padding: 0;
      display: grid;
      place-items: center;
    } */
  
    .content {
      max-width: 1800px;
      /* Adjust this value as needed */
      width: 100%;
      margin: 0 20px;
      /* This creates margins on both sides */
      text-align: center;
      /* This centers the content inside the container */
    }
     .image-logo {
     width: 100%;
        height: 250px;
        object-fit: cover;
        object-position: 0% 68%;
     }
    .SRTitle{ margin: 0px 0px 0px 40px;}
    /* Add your CSS styles for the banner and navigation here */
    .banner,
    .footer {
      background-color: #d6f2f6;
      /* Adjust color as needed */
      color: #456176;
      /* Adjust text color as needed */
      padding: 10px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      font-family: 'Lato', Helvetica, Arial, Lucida, sans-serif;
      flex-wrap: wrap;
    }
  
    h2,
    h3,
    h4,
    h5,
    h6 {
      color: #456176;
      padding-bottom: 10px;
      line-height: 1em;
      font-family: 'Lato', Helvetica, Arial, Lucida, sans-serif;
      font-size: 1.8em;
      font-weight: 500;
    }
  
    p {
      color: #456176;
    }
  
    .logo {
      width: 100px;
      /* Adjust logo size as needed */
      height: auto;
    }
  
    .title-wrapper {
      display: flex;
      align-items: center;
      /* Aligns content vertically */
    }
  
    nav ul {
      list-style-type: none;
      padding: 0;
      font-size: 24px;
      display: flex;
    }
  
    nav ul li {
      margin-right: 20px;
    }
  
    nav ul li a {
      color: #363636;
      /* Adjust link color as needed */
      text-decoration: none;
    }
  
    nav ul li a:hover {
      text-decoration: underline;
    }
  
    .is-highlight_effect {
      box-shadow: inset 0 -30px 0 rgba(26, 187, 169, 0.1);
    }
  
    .is-highlight_effect {
      color: #006661;
      box-shadow: inset 0 -36px 0 rgba(26, 187, 169, 0.1);
      display: block;
    }
  .main-content {
      font-size: 1.2em;
      max-width: 500px;
      margin: auto;
  }
  .main-quote {
      font-size: 1.1em;
      font-weight: 500;
       color: #006661 !important;
  }
    .heading_hero {
      margin-top: 0;
      margin-bottom: 1.5rem;
    }
  
    .hero {
      letter-spacing: -0.5px;
      margin-top: 1em;
      margin-bottom: 0.5em;
      font-family: Avenirnextltpro, sans-serif;
      font-size: 3.4em;
      font-weight: 700;
      line-height: 1.2;
    }
  
    .bar_hero {
      background-color: #ced1d1;
      width: 8em;
      height: 0.5em;
      margin-top: 4em;
      font-style: normal;
    }
.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
